import dynamic from 'next/dynamic'

import { ANALYTICS_PAGE_TYPES } from '../../analytics/types'
import { PresellLayoutProps } from '../../glamnetic/presellPage/PresellLayout'
import { LightLayoutProps } from '../components/Layout/LightLayout'
import { MainLayoutProps } from '../components/Layout/MainLayout'

const LightLayout = dynamic(() => import('../components/Layout/LightLayout'))
const MainLayout = dynamic(() => import('../components/Layout/MainLayout'))
const GlamneticPreSellLayout = dynamic(
  () => import('../../glamnetic/presellPage/PresellLayout')
)
const GlamneticMainLayout = dynamic(() => import('../../glamnetic/Layout'))
const ZitstickaMainLayout = dynamic(
  () => import('../../zitsticka/components/layout/Layout')
)

export type LayoutVariants = 'main' | 'light' | 'presell'

export type WithLayoutOptions = (
  | MainLayoutProps
  | LightLayoutProps
  | PresellLayoutProps
) & {
  pageType: ANALYTICS_PAGE_TYPES | null
  transparent?: boolean
  showServices?: boolean
  showCart?: boolean
}

const defaultLayouts: {
  [key in LayoutVariants]?: unknown
} = {
  main: MainLayout,
  light: LightLayout,
}

const brandLayouts = {
  glamnetic: {
    main: GlamneticMainLayout,
    presell: GlamneticPreSellLayout,
  },
  zitsticka: {
    main: ZitstickaMainLayout,
  },
}

export const getBrandLayout = (
  brand: string | undefined,
  variant: LayoutVariants
) => {
  if (!brand || !brandLayouts[brand] || !brandLayouts[brand][variant]) {
    return defaultLayouts[variant] ? defaultLayouts[variant] : MainLayout
  }

  return brandLayouts[brand][variant]
}
