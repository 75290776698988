import { Button } from '@chakra-ui/button'
import { Box, Flex, FlexProps, Text } from '@chakra-ui/layout'
import { useTheme } from '@chakra-ui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'

import FillImage from 'modules/shared/components/FillImage'
import Heading from 'modules/shared/components/Heading'
import NextLink from 'modules/shared/components/NextLink'

interface BundleCardProps extends FlexProps {
  title: string
  description: string
  isInternal?: boolean
  image: string
  url: string
}

const ShopCard: React.FC<BundleCardProps> = ({
  url,
  title,
  image,
  isInternal,
  description,
  objectFit,
  ...props
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Flex
      direction={['row', 'column']}
      minH={['142px', '685px']}
      position="relative"
      borderRadius="1rem"
      overflow="hidden"
      bg="pmallBgCard"
      w="100%"
    >
      <Box
        pt={[null, '2.5rem']}
        py={['1.31rem', null]}
        px={['0.87rem', '3.12rem']}
        maxW={['65%', 'none']}
        zIndex="1"
      >
        <Heading
          as="h3"
          variant="h5"
          fontFamily="bold"
          lineHeight={['1rem', '2rem']}
          color="white"
          fontWeight="bold"
        >
          {title}
        </Heading>
        <Text size="md" color="white" mt={['0.5rem', '1rem']}>
          {description}
        </Text>

        <NextLink isInternal={isInternal} href={url} hardRefresh>
          <Button
            w="auto"
            color="drawerTextColor"
            p={[null, '1rem 2.38rem']}
            my={['1rem', '2.5rem']}
          >
            <Text color="white" size="sm">
              {t('common:shop-products', 'Shop Products')}
            </Text>
          </Button>
        </NextLink>
      </Box>

      <Flex
        w="100%"
        flex="1"
        right="0"
        bottom="0"
        alignItems="flex-end"
        justifyContent="center"
        position="absolute"
        maxW={['40%', 'none']}
        h="100%"
        maxH="27.12rem"
        css={{
          [`@media screen and (max-width: ${theme.breakpoints[0]})`]: {
            '&': {
              '-webkit-mask-image':
                'linear-gradient(-90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0));',
            },
          },
        }}
        {...props}
      >
        <FillImage
          src={image}
          alt={title}
          objectPosition="bottom"
          w="100%"
          h="100%"
        />
      </Flex>
    </Flex>
  )
}

export default ShopCard
