import { Box, ImageProps as ChakraImageProps } from '@chakra-ui/core'
import NextImage, { ImageProps } from 'next/image'
import React, { useState } from 'react'

export type ChakraNextImageProps = {
  placeholderImage?: string
} & ImageProps &
  ChakraImageProps

/**
 * Renders an optimized image that will fill whichever measurements the element
 * takes.
 */
export const FillImage: React.FC<Omit<ChakraNextImageProps, 'layout'>> = ({
  alt,
  src,
  width,
  height,
  loader,
  quality,
  priority,
  loading,
  lazyBoundary,
  placeholder,
  blurDataURL,
  unoptimized,
  placeholderImage,
  objectFit = 'contain',
  objectPosition,
  onLoadingComplete,
  ...boxProps
}) => {
  const [error, setError] = useState(false)

  const source = error && placeholderImage ? placeholderImage : src

  return (
    <Box {...boxProps} position="relative">
      {src && (
        <NextImage
          alt={alt}
          src={source}
          width={width}
          height={height}
          loader={loader}
          quality={quality}
          priority={priority}
          loading={loading}
          lazyBoundary={lazyBoundary}
          placeholder={placeholder}
          blurDataURL={blurDataURL}
          unoptimized={unoptimized}
          objectFit={objectFit}
          objectPosition={objectPosition}
          onLoadingComplete={onLoadingComplete}
          onError={() => setError(true)}
          layout="fill"
          draggable={false}
        />
      )}
    </Box>
  )
}

export default FillImage
