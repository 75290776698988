import Head from 'next/head'
import React from 'react'

import { ucFirst } from '../../../utils/string'

type PageHeaderProps = {
  title: string
  description?: string
  image_url?: string
  ogType?: 'website' | 'product'
  ogDescription?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  title,
  description,
  image_url,
  ogType = 'website',
  ogDescription,
}) => {
  const desc = description

  const capitalizedTitle = ucFirst(title)
  const openGraphTitle = `${capitalizedTitle} | Pangaea Mall`

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.png" />
        <title>{capitalizedTitle} | Pangaea Mall</title>
        <meta name="description" content={desc} />
        <meta name="theme-color" content="#557b97" />

        <meta property="og:title" content={openGraphTitle} />
        <meta property="og:description" content={ogDescription || desc} />
        <meta property="og:image" content={image_url} />
        <meta property="og:image:secure_url" content={image_url} />
        <meta property="og:site_name" content="Pangaea Mall" />
        <meta property="og:type" content={ogType} />
        <meta property="og:image:alt" content={openGraphTitle} />
      </Head>
      {children}
    </>
  )
}
export default PageHeader
