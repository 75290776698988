import { Link as ChakraLink, LinkProps } from '@chakra-ui/core'
import Link from 'next/link'
import React from 'react'

export type NextLinkProps = Omit<LinkProps, 'as'> & {
  href: string
  as?: string
  isInternal?: boolean
  onClick?: () => void
  hardRefresh?: boolean
  shouldScroll?: boolean
}

const NextLink: React.FC<NextLinkProps> = ({
  href,
  isInternal = true,
  onClick = () => {},
  as,
  hardRefresh = false,
  shouldScroll = false,
  children,
  ...rest
}) => {
  return hardRefresh || !isInternal ? (
    <ChakraLink href={href} onClick={onClick} {...rest}>
      {children}
    </ChakraLink>
  ) : (
    <Link href={href} as={as} scroll={shouldScroll} passHref>
      <ChakraLink onClick={onClick} {...rest}>
        {children}
      </ChakraLink>
    </Link>
  )
}

export default NextLink
