import { FlexProps } from "@chakra-ui/layout";
import { TFunction } from "i18next";

export interface ShopItem extends FlexProps {
  title: string
  description: string
  isInternal?: boolean
  image: string
  url: string
}

export const getShopData  = (t: TFunction): ShopItem[] => [
  {
    title: t('pmallHomePage:zitsticka-title', 'Shop Zitsticka Products'),
    description: t('pmallHomePage:zitsticka-description', 'Face technology for every stage. From A to Zit.'),
    image: '/img/pmall/zitsticka.png',
    objectFit: 'contain',
    url: '/zitsticka/products'
  },
  {
    title: t('pmallHomePage:glamnetic-title', 'Shop Glamnetic Products'),
    description: t('pmallHomePage:glamnetic-description', 'Magnetic Liners and Lashes'),
    image: '/img/pmall/glamnetic.png',
    url: '/glamnetic/products'
  },
  {
    title: t('pmallHomePage:meridian-title', 'Shop Meridian Products'),
    description: t('pmallHomePage:meridian-description', 'Grooming for below-the-waist'),
    image: '/img/pmall/meridian.png',
    objectFit: 'contain',
    url: 'https://www.meridiangrooming.com/',
    isInternal: false
  },
  {
    title: t('pmallHomePage:lumin-title', 'Shop Lumin Products'),
    description: t('pmallHomePage:lumin-description', 'Skin management for men'),
    image: '/img/pmall/lumin.png',
    url: 'https://www.luminskin.com/',
    isInternal: false
  }
];