import { TFunction } from "i18next";

import staticRoutes from "modules/static/routes";

const getFooterData = (t: TFunction) => [
  {
    title: t('nav:about-pmall', 'About PMall'),
    href: staticRoutes.about(),
  },
  {
    title: t('nav:terms-of-use', 'Terms of Service'),
    href: staticRoutes.termsOfService(),
  },
  {
    title: t('nav:privacy-policy', 'Privacy Policy'),
    href: staticRoutes.privacyPolicy(),
  },
];

export default getFooterData;