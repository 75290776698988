import { NextPage } from 'next'
import React from 'react'

import { useTypedSelector } from '../../../redux/store'
import { selectBrand } from '../selectors'
import {
  getBrandLayout,
  LayoutVariants,
  WithLayoutOptions,
} from '../utils/getBrandLayout'

function withLayout<T>(
  Component: NextPage<T>,
  variant: LayoutVariants,
  options: WithLayoutOptions
) {
  const { pageType, ...layoutsProps } = options

  const WithLayoutComponent = (props: T) => {
    const brand = useTypedSelector(selectBrand())
    const Layout = getBrandLayout(brand, variant)

    return (
      <Layout {...layoutsProps}>
        <Component {...props} />
      </Layout>
    )
  }
  if ('getInitialProps' in Component) {
    console.log('has initial props')
    WithLayoutComponent.getInitialProps = Component.getInitialProps
  }

  return WithLayoutComponent
}

export default withLayout
