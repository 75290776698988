import {
  Box,
  Divider,
  Flex,
  VStack,
  Text,
  useDisclosure,
  useTheme
} from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import getFooterData from 'modules/pmall/constants/footerData'
import { OutlinePlusIcon } from 'modules/shared/components/Icons/OutlinePlus'
import NextLink from 'modules/shared/components/NextLink'
import staticRoutes from 'modules/static/routes'

const Footer: React.FC = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const { isOpen, onToggle } = useDisclosure()

  const transition = `all ${theme.durations.default} ${theme.easings.default}`

  return (
    <Flex as="footer" direction="column" h="100%">
      <Box pb={[0, 10]} px={[0, 18]}>
        <Divider color="seastone" display={['none', 'block']} mb={10} />

        <Box
          borderY={1}
          borderStyle="solid"
          borderColor="seastone"
          display={['block', 'none']}
        >
          <Flex
            py={3}
            px={4}
            w="100%"
            as="button"
            align="center"
            justify="space-between"
            onClick={onToggle}
          >
            <Text fontSize="lg" fontWeight="bold">
              {t('footer:information', 'Information')}
            </Text>
            <OutlinePlusIcon
              h={14}
              color="textPrimary"
              transform={isOpen ? 'rotate(-45deg)' : ''}
              transition={transition}
            />
          </Flex>

          <Box overflow="hidden" maxH={isOpen ? '190px' : 0} transition={transition}>
            <VStack spacing={4} px={4} mb={4} align="start">
              {getFooterData(t).map((item, index) => (
                <NextLink shouldScroll={true} key={index} href={item.href}>
                  <Text variant="cardBody">{item.title}</Text>
                </NextLink>
              ))}
            </VStack>
          </Box>
        </Box>

        <Flex
          direction={["column", "column", "row"]}
          justifyContent={["center", "space-between" ]}
          flex="1"
        >
          <Flex mb={[0, "1.5rem", 0]} justifyContent="center" flex="1" display={['none', 'flex']}>
            {getFooterData(t).map((item, index) => (
              <NextLink shouldScroll={true} key={index} href={item.href} mx={5}>
                <Text variant="cardBody" textAlign="center">
                  {item.title}
                </Text>
              </NextLink>
            ))}
          </Flex>

          <NextLink href={staticRoutes.about()}>
            <Text
              as={Flex}
              justifyContent="center"
              fontSize={['md', 'lg']}
              color="textPrimary"
              py={['1rem', "0"]}
            >
              {t('footer:powered-by', 'Powered by')}&nbsp;
              <Text as="span" color="footerPMall" fontSize={['md', 'lg']}>
                PANGAEA MALL
              </Text>
            </Text>
          </NextLink>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Footer
