import { useTranslation } from 'react-i18next'

import withLayout from '../modules/brand/hoc/withLayout'
import PageHeader from '../modules/shared/components/PageHeader'

import HomeMainSection from 'modules/pmall/components/HomeElements/HomeMainSection'

function PMallHomePage() {
  const { t } = useTranslation()

  return (
    <PageHeader
      title={t('meta:homepage.title', 'Home Page')}
      description={t(
        'meta:homepage.description',
        'We break down the barriers. Shop Leading Products from anywhere in the world with PMall.'
      )}
      image_url="/img/pmall/og-image.png"
    >
      <HomeMainSection />
    </PageHeader>
  )
}

export default withLayout(PMallHomePage, 'light', {
  pageType: 'home_page',
  enableRTL: true,
  hasFooter: false,
  hasTopNav: false,
  bgColor: 'white',
})
