import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const OutlinePlusIcon = createIcon({
  displayName: 'OutlinePlusIcon',
  viewBox: '0 0 14 14',
  path: (
    <>
      <path fill="currentColor" d="M8 8V14H6V8H0V6H6V0H8V6H14V8H8Z" />
    </>
  ),
})
