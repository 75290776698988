import { Flex, Grid } from '@chakra-ui/layout'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import TopNav from './TopNav'

import Footer from 'modules/pmall/components/HomeElements/Footer'
import Header from 'modules/pmall/components/HomeElements/Header'
import ShopCard from 'modules/pmall/components/HomeElements/ShopCard'
import { getShopData } from 'modules/pmall/constants/shopData'
import Heading from 'modules/shared/components/Heading'
import { scrollTo } from 'utils/scrollTo'

export default function HomeMainSection() {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  return (
    <>
      <Flex w="100%" justifyContent="center" alignItems="center">
        <Flex
          w="100%"
          maxW="85rem"
          px={['1.18rem', '2.5rem', '2.5rem']}
          pb={['1.18rem', '2.5rem', '2.5rem']}
          alignItems="center"
          direction="column"
        >
          <TopNav />

          <Header onClick={() => scrollTo(ref)} />

          <Heading
            as="h2"
            variant="h3"
            lineHeight="1.5"
            my={['1rem', '2.5rem']}
            fontFamily="extraBold"
            color="textPrimary"
            fontWeight="bold"
          >
            {t('shop-from-stores', 'Shop from Stores')}
          </Heading>

          <Grid
            ref={ref}
            w="100%"
            templateColumns={['1fr', 'repeat(2, 1fr)']}
            gap={['1rem', '3.25rem']}
          >
            {getShopData(t).map(
              (
                { title, description, isInternal, image, objectFit, url },
                key
              ) => (
                <ShopCard
                  key={key}
                  title={title}
                  description={description}
                  isInternal={isInternal}
                  objectFit={objectFit}
                  image={image}
                  url={url}
                />
              )
            )}
          </Grid>
        </Flex>
      </Flex>

      <Footer />
    </>
  )
}
