import { ArrowDownIcon } from '@chakra-ui/icons'
import { Box, BoxProps, Flex, Text } from '@chakra-ui/layout'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'modules/shared/components/Heading'

const Header: React.FC<BoxProps> = ({ onClick = null }) => {
  const { t } = useTranslation()

  return (
    <Box
      w="100%"
      h={['10rem', '24rem']}
      borderRadius="1rem"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundImage="url('/img/pmall/hero.png')"
      position="relative"
      onClick={onClick}
      cursor={onClick ? 'pointer' : undefined}
    >
      <Flex
        backgroundColor="#00000080"
        justifyContent="center"
        borderRadius="1rem"
        alignItems="center"
        direction="column"
        pt={['1rem', '5rem']}
        h="100%"
        w="100%"
      >
        <Heading variant="h1" color="white" fontWeight="bold">
          {t('home:header-title', 'Shop Leading Products')}
        </Heading>
        <Text
          marginTop={[null, '1rem']}
          color="white"
          textAlign="center"
          fontSize={['0.6rem', 'lg']}
        >
          {t(
            'home:header-description',
            'Premium products and premium values with PMall'
          )}
        </Text>
        <ArrowDownIcon
          w={[15, 38]}
          h={[15, 38]}
          marginTop={['1.6rem', '5.6rem']}
          color="white"
        />
      </Flex>
    </Box>
  )
}

export default Header
