import { Flex, Text, FlexProps } from '@chakra-ui/layout'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Heading from 'modules/shared/components/Heading'
import NextLink from 'modules/shared/components/NextLink'
import staticRoutes from 'modules/static/routes'

interface Props extends FlexProps {}

const TopNav: React.FC<Props> = ({ ...flexProps }) => {
  const { t } = useTranslation()

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      px="0.3rem"
      py={['1rem', '2rem']}
      mr="auto"
      ml="auto"
      {...flexProps}
    >
      <NextLink href="/">
        <Heading
          as="h2"
          variant="h6"
          fontFamily="extraBold"
          color="textPrimary"
        >
          PANGAEA MALL
        </Heading>
      </NextLink>
      <NextLink href={staticRoutes.about()} mx={5}>
        <Text lineHeight="2rem" size="md" color="textPrimary">
          {t('nav:about-pmall', 'About PMall')}
        </Text>
      </NextLink>
    </Flex>
  )
}

export default TopNav
